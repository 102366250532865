import type { searchKeyword } from './model'
import http from '~/utils/http'

// 片源搜索
export function searchVideo(params: searchKeyword) {
  return http.post<any>({
    url: '/jav/search/video',
    body: params
  })
}

// 热搜列表
export function getHotList(params: any) {
  return http.post<any>({
    url: '/jav/search/hotList',
    body: params
  })
}
